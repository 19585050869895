<template>
  <v-container>
    <v-card class="glow" :loading="loading">
      <v-img height="150" src="../../assets/card-bg-only-racer.jpg">
        <v-card-title class="white--text">
          <h3 class="pa-2">
            {{ isParentConsentInitialized ? "Parental Consent Required" : "No Parent Consent Process Initiated" }}
          </h3>
        </v-card-title>
      </v-img>

      <v-card-text>
        <ApiError v-if="allowMinorRegistration" :errors="errors" class="mt-4"></ApiError>

        <template v-if="!allowMinorRegistration">
          <ComingSoon />
        </template>

        <template v-else-if="status">
          <div class="text-center pa-5">
            <p v-html="statusMessage"></p>
            <p v-if="status == 'uninitialized'" class="text-center pa-5">
              To proceed, please provide some missing information for both the parent/guardian and the rider. This will
              help us ensure a smooth consent process.
              <v-form class="mt-10" v-model="valid" @submit.stop.prevent="onSubmit">
                <v-row>
                  <v-col class="text-center" cols="12">
                    <DateTimePickerField
                      v-if="!selectedParticipant.user.birthDate"
                      dense
                      outlined
                      v-model="form.user.birthDate"
                      :label="$i18n.translate('Birth Date')"
                      class="required"
                      :rules="rules.birthDate"
                      :error-messages="$error.getValidationError(errors, 'birthDate')"
                      @input="$error.clearValidationError(errors, 'birthDate')"
                      onlyDate
                      format="MM/DD/YYYY"
                      :max="today"
                    ></DateTimePickerField>
                    <EmailField
                      dense
                      id="parentEmail"
                      name="parentEmail"
                      :label="$i18n.translate('Parent Email')"
                      v-model="form.parentEmail"
                      :rules="rules.parentEmail"
                      :error-messages="$error.getValidationError(errors, 'parentEmail')"
                      @input="$error.clearValidationError(errors, 'parentEmail')"
                      outlined
                      class="required"
                    />
                    <v-text-field
                      v-if="!selectedParticipant.user.firstName"
                      dense
                      id="firstName"
                      name="firstName"
                      :label="$i18n.translate('Rider First Name')"
                      v-model="form.firstName"
                      :rules="rules.firstName"
                      :error-messages="$error.getValidationError(errors, 'firstName')"
                      @input="$error.clearValidationError(errors, 'firstName')"
                      outlined
                      class="required"
                    />
                    <EmailField
                      v-if="!selectedParticipant.user.email"
                      dense
                      id="email"
                      name="email"
                      :label="$i18n.translate('Rider Email')"
                      v-model="form.email"
                      :rules="rules.email1"
                      :error-messages="$error.getValidationError(errors, 'email1')"
                      @input="$error.clearValidationError(errors, 'email1')"
                      outlined
                      class="required"
                    />
                    <GenderField
                      v-if="!selectedParticipant.user.gender"
                      dense
                      name="gender"
                      v-model="form.gender"
                      :label="$i18n.translate('Rider Gender')"
                      :rules="rules.gender"
                      :error-messages="$error.getValidationError(errors, 'gender')"
                      @input="$error.clearValidationError(errors, 'gender')"
                      outlined
                      class="required"
                    ></GenderField>
                  </v-col>
                </v-row>
              </v-form>
            </p>
          </div>
        </template>

        <template v-else-if="isError">
          <p class="text-center pa-10">
            Something went wrong. Please contact Kawasaki for assistance.
          </p>
        </template>

        <template v-else-if="consentResent">
          <p class="text-center pa-10">
            The consent request has been resent successfully. An email has been sent to your parent{{
              selectedParticipant.user.parentEmail ? " " + selectedParticipant.user.parentEmail : ""
            }}.
          </p>
        </template>

        <template v-else-if="isParentConsentInitialized">
          <p class="text-center pa-10">
            Your account is currently on hold until a parent or guardian completes the consent process. Once approved,
            you’ll be able to access the platform fully.
          </p>
        </template>

        <template v-else>
          <p class="text-center pa-10">
            It looks like the parental consent process has not been initiated yet. To proceed, please retry the consent
            request by clicking the button below.
          </p>
        </template>
      </v-card-text>

      <v-card-actions>
        <v-btn
          v-if="allowMinorRegistration"
          color="primary"
          @click="handleConsent"
          :disabled="loading || consentResent"
        >
          {{ handleButtonText }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="backToLogin">Back to Login</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import ApiError from "@/gapp-components/components/display/ApiError.vue";
import ValidationService from "@/gapp-components/services/validation.service";
import { mapGetters } from "vuex/dist/vuex.common.js";
import EmailField from "../../gapp-components/components/fields/EmailField.vue";
import GenderField from "../../gapp-components/components/fields/GenderField.vue";
import ComingSoon from "../ComingSoon.vue";

export default {
  name: "PendingConsentNotification",
  components: { ApiError, EmailField, GenderField, ComingSoon },
  data() {
    return {
      consentResent: false,
      loading: false,
      errors: {},
      messages: {
        new:
          "Your parent consent process has not started yet. Please click the button below to resend the consent request",
        pending:
          "Your parent consent request is currently <strong>pending</strong>. Please ask your parent or guardian to check their email and complete the consent process.",
        declined:
          "Unfortunately, your parent consent request was <strong>declined</strong>. Please contact Kawasaki or try again.",
        uninitialized:
          "The parent consent process has not been initiated yet due to an error. Please contact with Kawasaki.",
        revoked: "Your parent consent request has been <strong>revoked</strong>. Please contact to Kawasaki.",
        processing:
          "Your parent consent request is <strong>processing</strong> and your account is currently on hold until a parent or guardian completes the consent process. Once approved, you’ll be able to access the platform fully.",
        resend: "The consent request has been resent successfully.",
        retry: "The parental consent process has been retried successfully."
      },
      isError: false,
      status: null, // 'pending', 'declined', 'revoked', 'approved', 'processing', 'resend', 'uninitialized'
      valid: false,
      form: {},
      allowMinorRegistration: false
    };
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedProgram"]),
    isParentConsentInitialized() {
      return this.$kawasaki.isParentConsentInitialized();
    },

    canResendConsentRequest() {
      return this.isParentConsentInitialized && !["declined", "revoked"].includes(this.status);
    },

    statusMessage() {
      if (this.status === "resend") {
        return `The consent request has been resent successfully. An email has been sent to your parent.`;
      }
      if (this.status === "retry") {
        return `The parental consent process has been retried successfully. An email has been sent to your parent.`;
      }
      return (
        this.messages[this.status] ||
        "We are unable to determine the current status of your request. Please contact Kawasaki or try again."
      );
    },

    handleButtonText() {
      if (this.loading) return "Sending...";

      return this.canResendConsentRequest ? "Resend parent consent" : "Retry parent consent request";
    },
    rules() {
      return {
        firstName: [
          ValidationService.generateNotWhitespacesRule("First Name"),
          ...ValidationService.generateTextValidationRules("First Name")
        ],
        email: [
          ...ValidationService.generateEmailValidationRules("Email"),
          v => v !== this.form.parentEmail || "Parent email and rider email cannot be the same"
        ],
        parentEmail: [
          ...ValidationService.generateEmailValidationRules("Parent Email"),
          v => v !== this.form.email || "Parent email and rider email cannot be the same"
        ],
        gender: ValidationService.generateDropdownValidationRules("Gender"),
        birthDate: [
          ...ValidationService.generateDateValidationRules("Birth Date"),
          v => this.isNotInFuture(v) || "Birthdate cannot be in the future"
        ]
      };
    }
  },
  methods: {
    async fetchConsentStatus() {
      try {
        this.loading = true;
        const { data } = await this.$api.get(`/api/privo/consent/status/${this.selectedParticipant.id}/public`);
        this.status = data && data.toLowerCase();
        this.isError = false;
      } catch (error) {
        console.error("Failed to fetch consent status:", error);
        this.isError = true;
        this.status = "uninitialized";
        const errorMessage = `The parent consent process has not been initiated yet.  Please contact Kawasaki or retry consent request.`;
        this.messages.uninitialized = errorMessage;
        this.errors = this.$api.getErrorsFromResponse(error);
      } finally {
        this.loading = false;
      }
    },
    async handleConsent() {
      this.loading = true;
      try {
        if (this.canResendConsentRequest) {
          await this.$api.post(`/api/privo/consent/resend/${this.selectedParticipant.id}/public`, this.form);
          this.status = "resend";
          this.errors = null;
        } else {
          await this.$api.post(`/api/privo/consent/request/${this.selectedParticipant.id}/public`, this.form);
          this.status = "retry";
          this.errors = null;
        }
        this.consentResent = true;
        this.isError = false;
      } catch (error) {
        console.error("Failed to resend consent request:", error);
        this.errors = this.$api.getErrorsFromResponse(error);
        this.consentResent = false;
        this.isError = true;
      } finally {
        this.loading = false;
      }
    },
    backToLogin() {
      this.$router.push({ name: "login" });
    }
  },
  async mounted() {
    this.allowMinorRegistration = this.selectedProgram?.allowMinorRegistration;
    this.form.parentEmail = this.selectedParticipant.user.parentEmail || "";
    await this.fetchConsentStatus();
  }
};
</script>
